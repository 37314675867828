var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container container--modifier catalog-form"},[_c('div',{staticClass:"row row--modifier block__top-row"},[_c('div',{staticClass:"col-sm-6 col-xs-12"},[_c('h2',{staticClass:"block__header"},[_vm._v(_vm._s(_vm.viewmodel.columnOneTitle))]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"block__content",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"catalog-form__input-fields"},[_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"customerName","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"customerName"}},[_vm._v(" "+_vm._s(_vm.translations["ordercatalog.name"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderForm.name),expression:"orderForm.name"}],attrs:{"type":"text","id":"customerName"},domProps:{"value":(_vm.orderForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderForm, "name", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"address","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"address"}},[_vm._v(" "+_vm._s(_vm.translations["ordercatalog.address"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderForm.address),expression:"orderForm.address"}],attrs:{"type":"text","id":"address"},domProps:{"value":(_vm.orderForm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderForm, "address", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"postalCodeAndCity","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"postalCodeAndCity"}},[_vm._v(" "+_vm._s(_vm.translations["ordercatalog.postalcodeandcity"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderForm.postalCodeAndCity),expression:"orderForm.postalCodeAndCity"}],attrs:{"type":"text","id":"postalCodeAndCity"},domProps:{"value":(_vm.orderForm.postalCodeAndCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderForm, "postalCodeAndCity", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"customerEmail","mode":"lazy","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"customerEmail"}},[_vm._v(" "+_vm._s(_vm.translations["ordercatalog.email"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderForm.email),expression:"orderForm.email"}],attrs:{"type":"text","id":"customerEmail"},domProps:{"value":(_vm.orderForm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.orderForm, "email", $event.target.value)}}})]}}],null,true)})],1),_c('button',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.translations["ordercatalog.send"])+" ")])])]}}])})],1),_c('div',{staticClass:"col-sm-6 col-xs-12 hide-mobile"},[_c('h2',{staticClass:"block__header"},[_vm._v(_vm._s(_vm.viewmodel.columnTwoTitle))]),_c('div',{staticClass:"block__content"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.viewmodel.columnTwoDescription)}}),_c('b',[_c('a',{attrs:{"href":_vm.viewmodel.url}},[_vm._v(_vm._s(_vm.viewmodel.linkText))])])])])]),(_vm.showThankYouModal)?_c('thank-you-modal',{class:{ 'thank-you-modal--open': _vm.showThankYouModal },attrs:{"thankYouMsg":_vm.viewmodel.blockRichTextThankYouMessage,"thankYouHeader":_vm.viewmodel.blockRichTextThankYouTitle},on:{"closeModal":_vm.handleCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }