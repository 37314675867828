import { FavouriteStore } from '@/store/bricmate-b2c/favouriteState';
import { ApiService } from '@/services/bricmate/ApiService';
import ThankYouModal from './ThankYouModal.vue';

export default {
    name: "SamplesForm",
    props: ['viewmodel'],
    components: { ThankYouModal },
    data() {
        return {
            userForm: {
                companyName: '',
                projectName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',
                freeText: '',
                orderCatalog: false,
                favouriteProducts: []
            },
            showModal: false,
        };
    },
    computed: {
        translations() {
            return window.__litium.translation;
        }
    },
    methods: {
        onSubmit() {
            this.userForm.favouriteProducts = FavouriteStore.favouritesIds;
            ApiService.orderSamples(this.userForm);
            this.showModal = true;            
        },
        resetForm() {
            this.userForm = {
                companyName: '',
                projectName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',
                freeText: '',
                orderCatalog: false,
            };
            this.$nextTick(() => this.$refs.observer.reset());
        },
        handleCloseModal() {
            this.showModal = false;
            this.resetForm();
        }
    },
};