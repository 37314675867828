import { Guid } from "guid-typescript";
import { getStoreBuilder, BareActionContext } from "vuex-typex";
import { RootState } from "./index";

export interface FavouriteState {
    favouriteIds: Guid[],
}

function getStringFromLocalStorage (): string{
    const favourites = localStorage.getItem("favourites");
    if(favourites){
        return favourites;
    }else{
        return "[]";
    }
}

const initialState: FavouriteState = {
    favouriteIds: JSON.parse(getStringFromLocalStorage()),
};

const store = getStoreBuilder<RootState>().module("favourite", initialState);

function getStoreFavourites(state: FavouriteState): Guid[] {
    return state.favouriteIds;
}


// getters
const getFavourites = store.read(getStoreFavourites, "getStoreTranslations");


// mutations
function commitFavourites(state: FavouriteState, favourites: Guid[]): void {
    state.favouriteIds = favourites;
}

function commitAddToFavouritesIds(state: FavouriteState, newFavouriteId: Guid): void {
    const fav = localStorage.getItem("favourites");
    let favArray: Guid[] = [];
    if (fav !== null) {
        favArray = JSON.parse(fav);
    }

    if (favArray.includes(newFavouriteId)) {
        for (let index = 0; index < favArray.length; index++) {
            const element = favArray[index];
            if (element === newFavouriteId) {
                favArray.splice(index, 1);
            }
        }
    } else {
        favArray.push(newFavouriteId);
    }
    localStorage.setItem("favourites", JSON.stringify(favArray));
    state.favouriteIds = favArray;
}


function commitRemoveFromFavouritesIds(state: FavouriteState, newFavouriteId: Guid): void {
    const fav = localStorage.getItem("favourites");
    let favArray: Guid[] = [];
    if (fav !== null) {
        favArray = JSON.parse(fav);
    }

    if (favArray.includes(newFavouriteId)) {
        for (let index = 0; index < favArray.length; index++) {
            const element = favArray[index];
            if (element === newFavouriteId) {
                favArray.splice(index, 1);
            }
        }
    }

    localStorage.setItem("favourites", JSON.stringify(favArray));
    state.favouriteIds = favArray;
}

// actions
async function saveFavouriteState(context: BareActionContext<FavouriteState, RootState>, payload: FavouriteState): Promise<void> {
    // Do the API call here and then commit the result
    commitFavourites(context.state, payload.favouriteIds);
}

async function addToFavouriteState(context: BareActionContext<FavouriteState, RootState>, payload: Guid): Promise<void> {
    // Do the API call here and then commit the result
    commitAddToFavouritesIds(context.state, payload);
}

async function removeFromFavouriteState(context: BareActionContext<FavouriteState, RootState>, payload: Guid): Promise<void> {
    // Do the API call here and then commit the result
    commitRemoveFromFavouritesIds(context.state, payload);
}

export const FavouriteStore = {
    get favouritesIds() { return getFavourites(); },
    saveFavouriteState: store.dispatch(saveFavouriteState, "saveFavouriteState"),
    addToFavouriteIdsState: store.dispatch(addToFavouriteState, "addToFavouriteState"),
    removeFromFavouriteState: store.dispatch(removeFromFavouriteState, "removeFromFavouriteState"),
};
