import {FavouriteStore} from '@/store/bricmate-b2c/favouriteState'

export default {
    name: "Favourite",
    props: ['url'],
    data() {
        return {
        };
    },
    computed: {
        favouriteCount: function(){
            return FavouriteStore.favouritesIds.length;
        }
    },
    methods: {    
        directToFavouritePage(){
            window.location.href = this.url;
        }
    },
};