import MenuLink from "./MenuLink.vue"
import BricmateCountryPicker from "./countryPicker/BricmateCountryPicker.vue";

export default {
  name: "MenuB2cBricmate",
  props: ["menulinks","bricmatecountries","showcountrypicker"],
  data() {
    return {
      showMenu: false,
      showProducts: false,
      showInspiration: false
    };
  },
  components: {
    MenuLink,
    BricmateCountryPicker
  },
  methods: {
    handleShowMenu() {
      this.showMenu = !this.showMenu;
    },
    handleShowProduct() {
      this.showProducts = !this.showProducts;
    },
    handleShowInspiration() {
      this.showInspiration = !this.showInspiration;
    },
  },
};