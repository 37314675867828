
export default {
    name: "ArrowButton",
    props: { arrowType: String },
    data() {
        return {
        };
    },
    computed: {
        arrowBtnClassName(){
            if(this.arrowType == 'right'){
                return 'arrow__btn--next'
            }else{
                return 'arrow__btn--prev'
            }
        }
    },
    methods: {
            
    },
};