import {toggleSearchBarFunc, submitSearchQuery} from '@/scripts/bricmate/application.js';

export default {
    name: "SearchB2cBricmate",
    props:["url"],
    data() {
      return {
        searchQuery: '',
      };
    },
    methods: {
        toggleSearchBar(){
            toggleSearchBarFunc();
        },
        handleSubmit(){
            submitSearchQuery(this.searchQuery, this.url);
        }
    }    
  };