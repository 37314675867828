window.addEventListener("DOMContentLoaded", (event) => {
    var prevScrollpos = window.pageYOffset;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down"
    const header = document.getElementsByClassName("header")[0];

    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos == 0) {
            header.classList.add(scrollUp);
            header.classList.remove(scrollDown);
        }
        else if (prevScrollpos > currentScrollPos) {
            header.classList.add(scrollUp);
            header.classList.remove(scrollDown);
        } else {
            header.classList.add(scrollDown);
            header.classList.remove(scrollUp);
        }
        prevScrollpos = currentScrollPos;
    }

});

export function toggleSearchBarFunc() {
    var searchBar = document.getElementsByClassName('search-bar')[0];
    var overlay = document.getElementById('backgroundOverlay');
    var openButton = document.getElementsByClassName('header__icon-search')[0];
    var closeBtn = document.getElementsByClassName('search__close-btn')[0];
    var inputField = document.getElementsByClassName('search__input-field')[0];

    document.onclick = function (e) {
        if (e.target.id == 'backgroundOverlay' || e.target == closeBtn) {
            searchBar.classList.remove('search-bar--active')
            overlay.style.display = 'none';
        }
        if (e.target === openButton) {
            searchBar.classList.add('search-bar--active')
            overlay.style.display = 'block';
            inputField.focus();
            inputField.select();

        }
    };
}

export function submitSearchQuery(query, url) {
    var searchPage = url + "?q=" + query;
    var searchBar = document.getElementsByClassName('search-bar')[0];
    var overlay = document.getElementById('backgroundOverlay');
    searchBar.classList.remove('search-bar--active')
    overlay.style.display = 'none';
    

    window.location.href = searchPage;
}

window.onload = toggleSearchBarFunc();


// Handle ios focus outeline
// https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
function handleFirstTab(e) {
    if (e.keyCode === 9) { // the "I am a keyboard user" key
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);

//IE11 polyfill source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
if (!Object.entries) {
    Object.entries = function( obj ){
      var ownProps = Object.keys( obj ),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
      while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
  
      return resArray;
    };
}