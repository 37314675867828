import { dectectMobileDevice } from "@/scripts/shared/sharedScripts.js";

export default {
    name: "Card",
    props: ["imgSrcset", "imgSrc", "useZoomImage", "usedInModal"],
    data() {
        return {
          zoomed: false,
          zoomMaxScale: 5,
          currentDevice: "desktop"
        };
      },
    methods: {
        IsMobileDevice(){
            return dectectMobileDevice("1024");
        }
    },
    watch:{
        zoomed: function(val){
            this.$emit('handleZoomedIn', val);
        },
        imgSrc:function() {
            if(this.$refs.zoomComponent){
                this.$refs.zoomComponent.reset();
            }
        }
    },
    mounted(){
        if(this.IsMobileDevice()){
            this.currentDevice = "touchDevice";
        }
    }
};