import Vue from "vue";
import Vuex, { Store } from "vuex";
import { getStoreBuilder } from "vuex-typex";

// Each module must be explicitly imported here
// so that they are built before the store is built.
import "./favouriteState";
import {FavouriteState} from "./favouriteState";

import "./checkoutstate";
import { CheckoutState } from "./checkoutstate";


export interface RootState {
    favourites: FavouriteState,
    checkout: CheckoutState;
}

Vue.use(Vuex);

const store: Store<RootState> = getStoreBuilder<RootState>().vuexStore();
export default store;
