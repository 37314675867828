import { style } from "@/models/bricmate/gMapOptions"
import { mark, store } from "@/models/bricmate/store";
import { gmapApi } from 'vue2-google-maps';
import GmapCustomMarker from 'vue2-gmap-custom-marker';

export default {
  name: "Gmap",

  components: {
    GmapCustomMarker
  },
  data() {
    return {
      //a default center for the map
      map: null,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      // center object is needed to prevent errors in gmap component
      center: { lat: 0, lng: 0 },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      mapStyle: {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        styles: style
      },
      showInfoModal: false,
      selectedMarker: {} as mark,
      placeService: null,
    };
  },
  props: ["filteredRegionsArray", "coordinates", "zoomLevel", "currentFilter"],
  mounted() {
    this.$refs.gmap.$mapPromise.then((map) => {
      if (this.coordinates != null && this.coordinates.split(',').length > 0) {
        const coordinateValues = this.coordinates.split(',')
        map.panTo({ lat: parseFloat(coordinateValues[0]), lng: parseFloat(coordinateValues[1].trim()) })
      }
      else {
        map.panTo({ lat: 60.128161, lng: 18.643501 })
      }
    })

    // A try to use placeService and get all the results (places) on the user input.
    // this.$gmapApiPromiseLazy().then(() => {
    //   this.$refs.gmap.$mapPromise.then((map) => {
    //     if (this.google) {
    //       this.placeService = new this.google.maps.places.PlacesService(map);
    //     }
    //   })
    // });
  },
  computed: {
    getModalClassName() {
      if (this.showInfoModal) {
        return 'gmap__modal--open'
      } else {
        return 'gmap__modal--close'
      }
    },
    google: gmapApi,
    translations: function () {
      return window.__litium.translation;
    },
    getStores() {
      const tempArray: store[] = []
      for (let index = 0; index < this.filteredRegionsArray.length; index++) {
        const region = this.filteredRegionsArray[index];
        for (let index = 0; index < region.storeInfos.length; index++) {
          const store = region.storeInfos[index];
          tempArray.push(store);
        }
      }
      return tempArray;
    }
  },
  methods: {
    toggleInfoWindow: function (marker, idx) {
      this.showInfoModal = !this.showInfoModal;
      this.selectedMarker = marker;
    },

    handleCloseModal: function () {
      this.showInfoModal = !this.showInfoModal
    },

    openLinkNewTab: function () {
      window.open(this.selectedMarker.websiteAddress, '_blank')
    },
    setCenter(newPos) {
      if (newPos.geometry != null) {
        this.$refs.gmap.$mapPromise.then((map: any) => {
          const pos = {
            lat: newPos.geometry.location.lat(),
            lng: newPos.geometry.location.lng(),
          }
          this.center = pos;
          map.setZoom(11);
        });
      }
    },
    handleFetchPosition() {
      this.$refs.gmap.$mapPromise.then((map: any) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            map.panTo({ lat: position.coords.latitude, lng: position.coords.longitude })
            map.setZoom(11);
          });
        }
      });
    },
    handleSearch() {

    },
    isCheckBoxChecked(category: string): boolean {
      if (this.selectedMarker && Object.keys(this.selectedMarker).length != 0 && this.selectedMarker.productCategoriesInStore != null) {
        return this.selectedMarker.productCategoriesInStore.includes(category);
      } else {
        return false;
      }
    },
    getStorePosition(storeInstance: store) {
      if (storeInstance && Object.keys(storeInstance).length != 0 && storeInstance.coordinates != null) {
        const coordinates = storeInstance.coordinates.split(",");
        const lat = coordinates[0];
        const lng = coordinates[1].trim();
        return { lat: parseFloat(lat), lng: parseFloat(lng) }
      } else {
        return {}
      }
    },
    filterCategories(filter: string) {
      this.$emit('filter-categories', filter);
    },
    isFilterSelected(filter: string): boolean {
      return this.currentFilter.includes(filter);
    },



    // A try to use placeService and get all the results (places) on the user input.
    // https://developers.google.com/maps/documentation/javascript/places

    // const map = new this.google.maps.Map(
    //   document.getElementById('gmap'), {});

    // this.$refs.gmap.$mapPromise.then((map: any) => {
    //   const inputValue = this.$refs.autocomplete.$refs.input.value;

    //   const request = {
    //     query: inputValue,
    //     fileds: []
    //   }

    //   var service = new this.google.maps.places.PlacesService(map);

    //   this.placeService.findPlaceFromQuery(request, function(results, status) {
    //     if (status === this.google.maps.places.PlacesServiceStatus.OK) {
    //       for (var i = 0; i < results.length; i++) {
    //         createMarker(results[i]);
    //       }
    //       map.setCenter(results[0].geometry.location);
    //     }
    //   });

    // });

  },
};