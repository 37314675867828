import Card from "./Card.vue";
import ArrowButton from "./ArrowButton.vue";
import Indicators from "./Indicators.vue";
export default {
  name: "Carousel",
  props: ["srcsetlist", "useZoomImage", "srclist", "usedInModal"],
  components: { Card, ArrowButton, Indicators },
  data() {
    return {
      currentElementIndex: 0,
      zoomedIn: false,
    };
  },
  computed: {
    currentImgSrcset() {
      return this.srcsetlist[this.currentElementIndex];
    },
    currentImgSrc(){
        return this.srclist[this.currentElementIndex];
    },
    reachedMaxLeft() {
      return this.currentElementIndex === 0;
    },
    reachedMaxRight() {
      return this.currentElementIndex === this.srcsetlist.length - 1;
    }
  },
  methods: {
    swipeHandler(direction) {
      if (direction === "right" && !this.reachedMaxLeft && !this.zoomedIn) {
        this.showPrevElement();
      }
      if (direction === "left" && !this.reachedMaxRight && !this.zoomedIn) {
        this.showNextElement();
      }
    },
    showNextElement() {
      this.currentElementIndex++;
    },
    showPrevElement() {
      this.currentElementIndex--;
    },
    showElement(elementIndex) {
      this.currentElementIndex = elementIndex;
    },
    handleZoomedIn(newValue){
      this.zoomedIn = newValue;
    }
  }
};