import Vue from "vue";
import store from "@/store/bricmate-b2c/index";
import "@/styles/bricmate-b2c/main.scss";
import MenuB2cBricmate from "@/components/bricmate/Menu.vue";
import SearchB2cBricmate from "@/components/bricmate/Search.vue"
import CatalogForm from "@/components/bricmate/CatalogForm.vue";
import ProductScaleChart from "@/components/bricmate/ProductScaleChart.vue";
import FindStore from "@/components/bricmate/FindStore.vue";
import AddToFavourite from "@/components/bricmate/AddToFavourite.vue";
import InteractiveVideo from "@/components/bricmate/InteractiveVideo.vue";
import Favourite from "@/components/bricmate/Favourite.vue";
import FavouriteListing from "@/components/bricmate/FavouriteListing.vue";
import ProductSheet from "@/components/bricmate/ProductSheet.vue";
import SamplesForm from "@/components/bricmate/SamplesForm.vue";
import ArchitectsOrdering from "@/components/bricmate/ArchitectsOrdering.vue"
import CookieLaw from "vue-cookie-law";
import Carousel from '@/components/bricmate/carousel/Carousel.vue';
import ProductPopUpModal from "@/components/bricmate/ProductPopUpModal.vue";
import BricmateCountryPicker from "@/components/bricmate/countryPicker/BricmateCountryPicker.vue";
import Vue2TouchEvents from 'vue2-touch-events'
import ProductPrint from "@/components/bricmate/ProductPrint.vue";
import BricmateProduct from "@/components/bricmate/product/BricmateProduct.vue"
import VueZoomer from 'vue-zoomer'
import BricmateQuantityPicker from "@/components/bricmate/product/BricmateQuantityPicker.vue";
import MiniCart from "@/components/bricmate/MiniCart/MiniCart.vue";
import CheckoutB2c from "@/components/bricmate/Checkout/CheckoutB2C.vue";
import OrderConfirmationB2c from "@/components/bricmate/Checkout/OrderConfirmationB2C.vue";

import * as VueGoogleMaps from 'vue2-google-maps'
import "@/scripts/bricmate/application.js";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "@/localization/bricmate/veelocalize";

Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("CookieLaw", CookieLaw);


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCEC5creWoGIjk9OoVaFL-QzmX2f8-MUCE',
    libraries: 'places',
  }
})

Vue.use(Vue2TouchEvents)

Vue.use(VueZoomer)

new Vue({
  components: {
    MenuB2cBricmate,
    CatalogForm,
    SearchB2cBricmate,
    ProductScaleChart,
    FindStore,
    AddToFavourite,
    Favourite,
    FavouriteListing,
    SamplesForm,
    ArchitectsOrdering,
    Carousel,
    ProductPopUpModal,
    InteractiveVideo,
    ProductSheet,
    BricmateCountryPicker,
    ProductPrint,
    BricmateProduct,
    BricmateQuantityPicker,
    MiniCart,
    CheckoutB2c,
    OrderConfirmationB2c
  },
  store,
  created() {

  }
}).$mount("#app");

