import GMap from "./GMap.vue";
import { ApiService } from "@/services/bricmate/ApiService";
import { region, store } from "@/models/bricmate/store";

export default {
    name: "FindStoreBricmate",
    props: ['model'],
    data() {
        return {
            regions: [] as region[],
            filteredRegions: [] as region[],
            currentFilter: [] as string[],
            searchQuery: '',
            currentPosition: { lat: '', lng: '' },
        };
    },
    components: {
        GMap
    },
    computed: {
        gMapLink() {
            return param => {
                const coordinates = param.split(",");
                const lat = coordinates[0];
                const lng = coordinates[1].trim();
                return `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
            }
        },
        translations: function () {
            return window.__litium.translation;
        },
    },
    methods: {
        async fetchStores() {
            return ApiService.fetchStores()
                .then((result) => {
                    return result.data;
                });
        },
        openMapNewTab(param) {
            const coordinates = param.split(",");
            const lat = coordinates[0];
            const lng = coordinates[1].trim();
            const url = `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`
            window.open(url, '_blank')
        },
        openWebsiteNewTab(url) {
            window.open(url, '_blank')
        },
        isCheckBoxChecked(storeInstance: store, category: string): boolean {
            return storeInstance.productCategoriesInStore.includes(category);
        },
        isFilterSelected(filter: string): boolean {
            return this.currentFilter.includes(filter);
        },
        updateCurrentFilter(newFilter: string) {
            if (this.currentFilter && this.currentFilter.length > 0 && this.currentFilter.includes(newFilter)) {
                this.currentFilter = this.currentFilter.filter(item => item != newFilter)
            } else {
                this.currentFilter.push(newFilter);
            }
        },
        filterCategories(newFilter: string) {
            this.updateCurrentFilter(newFilter);
            if (this.currentFilter.length != 0) {
                const tempRegion = [] as region[];
                for (let index = 0; index < this.regions.length; index++) {
                    const region = this.regions[index];
                    const tempStore = [] as store[];
                    if (region && region.storeInfos && region.storeInfos.length > 0) {
                        for (let j = 0; j < region.storeInfos.length; j++) {
                            const store = region.storeInfos[j];
                            if (store.productCategoriesInStore) {
                                if (this.currentFilter.every(filter => store.productCategoriesInStore.includes(filter))) {
                                    tempStore.push(store);
                                }
                            }
                        }
                    }
                    if (tempStore.length > 0) {
                        const result = { region: region.region, storeInfos: tempStore } as region;
                        tempRegion.push(result)
                    }
                }
                this.filteredRegions = tempRegion;
            } else {
                this.filteredRegions = this.regions;
            }
        },
    },

    mounted() {
        this.fetchStores()
            .then((result) => {
                this.regions = result;
                this.filteredRegions = this.regions;
            }
            );
    }
};