import ArchitectsOrderCatalog from "./ArchitectsOrderCatalog.vue"
import ArchitectsOrderCollection from "./ArchitectsOrderCollection.vue"


export default {
    name: "ArchitectsOrdering",
    props: ['model', 'images'],
    components: { ArchitectsOrderCatalog, ArchitectsOrderCollection },
    data() {
        return {
            selectedTab: 0,
        };
    },
    computed: {
        selectedTabContent() {
            return this.model.items[this.selectedTab];
        },
        translations() {
            return window.__litium.translation;
        },
        isActive() {
            return (index) => {
                return this.selectedTab == index
            }
        },
        showAdditionalText(){
            return this.selectedTabContent.blockItemFormLeftContentText || this.selectedTabContent.blockItemFormRightContentText;
        },
        additionalTextColWidth(){

            if(this.selectedTab === 2){
                return 'col-md-4'
            }else{
                return 'col-md-6'
            }
        }
        
    },
    methods: {
        selectContent(id) {
            this.selectedTab = id;
        },

    },

};