
export default {
    name: "ProductSheet",
    props: ['url'],
    data() {
        return {
        };
    },
    computed: {
        translations: function(){
            return window.__litium.translation;
        }
    },
    methods: {
        handleClick() {           
            var token = window.localStorage.getItem("requestVerificationToken");
            var verificationToken='';
            if(token!=null){
                verificationToken = token.split(':')[0];
            }
    
            if(verificationToken!=''){
                var pdfUrl = this.url+"&token="+verificationToken;
                window.open(pdfUrl,"_blank");
            }
        },        
    },

};