import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
    components: {
    },
})
export default class ProductScaleChart extends Vue {
    public translations: any;
    @Prop()
    public variantsize!: string;
    public showScaleChart!: boolean;

    constructor() {
        super();
        this.translations = window.__litium.translation;
        this.showScaleChart = false;
    }

    public toggleShowScaleChart() {
        this.showScaleChart = !this.showScaleChart;
    }

}
