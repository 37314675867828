import { Component, Vue } from "vue-property-decorator";
import { Spinner } from "spin.js";
import { CheckoutStore, CheckoutState } from "@/store/bricmate-b2c/checkoutstate";
import { ApiService } from '@/services/bricmate/ApiService';

@Component
export default class MiniCart extends Vue {
    get cart() { return CheckoutStore.cart; }
    protected spinner: Spinner;
    constructor() {
        super();
        this.spinner = new Spinner({ scale: 0.5 });
    }
    protected created() {
        const self = this;
        const $el = self.$el as HTMLElement;
        self.spinner.spin($el);
        ApiService.getMiniCart().then((response) => {
            if (response && response.data) {
                const payload: CheckoutState = {
                    cart: response.data,
                };
                CheckoutStore.saveState(payload).then(() => {/*nothing*/});
            }
            self.spinner.stop();
        });
    }

    redirectToCart(){
        window.location.href=this.cart.checkoutUrl;
    }
}
