import { Variant } from "@/models/bricmate/bricmateProduct";
import { ApiService } from '@/services/bricmate/ApiService';
import { AddOrUpdateCartItem, OrderRow, ProductPriceStockRequest, JeevesPriceRequest, JeevesPriceItem } from "@/models/tapwell/Cart";
import { CheckoutStore } from "@/store/bricmate-b2c/checkoutstate";
import { Spinner } from "spin.js";
import BricmateQuantityPicker from "./BricmateQuantityPicker.vue"
export default {
    name: "BricmateQuantityPicker",
    props: ['model'],

    data() {
      return {
        translations: window.__litium.translation,
        requestContext: window.__litium.requestContext,
        spinner: null,
        showMaxLimitMessage: false,
        quantityChangedTriggered: false,
        selectedVariant: null
      };
    },

    created() {
       
        this.spinner = new Spinner({ scale: 0.5 });

        if (this.model) {
          this.selectedVariant = this.model;
          this.selectedVariant.inStock = true;
          if (this.selectedVariant.quantity == undefined) {
            this.selectedVariant.quantity = 1;
          }
          this.selectedVariant.newQuantity = this.selectedVariant.quantity.toString();
        }

    },

    computed: {
        shopenabled() {
          if (this.requestContext.enableEcommerce === 'on') {
            return true;
          }
          return false;
        },
        orderRow() {
          return this.$store.state.checkout.cart.orderRows && this.$store.state.checkout.cart.orderRows.find((row) => row.articleNumber === this.model.id);
      },
      shouldShowButton() {
        return !(this.selectedVariant.outgoingProduct && !this.selectedVariant.inStock);
      }
    },

    components: { 
        BricmateQuantityPicker
    },
    
    methods: {   
        decreaseAmount() {
            this.$set(this.selectedVariant, 'quantity', this.selectedVariant.quantity - 1);
            if (this.selectedVariant.quantity < 0) {
              this.$set(this.selectedVariant, 'quantity', 0);
            }
            this.$set(this.selectedVariant, 'newQuantity', this.selectedVariant.quantity.toString());
            this.checkQuantitySize();
            this.checkMaximumQuantitySize();
            this.getProductPriceAndStock(this.selectedVariant, 1500);
            this.$forceUpdate();
          },
          increaseAmount() {
            this.$set(this.selectedVariant, 'quantity', this.selectedVariant.quantity + 1);
            this.$set(this.selectedVariant, 'newQuantity', this.selectedVariant.quantity.toString());
            this.checkQuantitySize();
            this.checkMaximumQuantitySize();
            this.getProductPriceAndStock(this.selectedVariant, 1500);
            this.$forceUpdate();
          },
          handleQtyChange() {
            if (this.selectedVariant.newQuantity.trim() == "") {
              this.$set(this.selectedVariant, 'newQuantity',  "1");
            }
            this.$set(this.selectedVariant, 'quantity',parseInt(this.selectedVariant.newQuantity));
            this.checkQuantitySize();
            this.checkMaximumQuantitySize();
            this.getProductPriceAndStock(this.selectedVariant, 1500);
            this.$forceUpdate();
          },
          checkQuantitySize() {
            if (this.selectedVariant.quantity > 99) {
              this.selectedVariant.quantityLarge = true;
            } else {
              this.selectedVariant.quantityLarge = false;
            }
          },
          checkMaximumQuantitySize() {
            if (parseInt(this.selectedVariant.maximumBuyableQuantitiy) > 0 && this.selectedVariant.quantity >= parseInt(this.selectedVariant.maximumBuyableQuantitiy)) {
              this.showMaxLimitMessage = true;
            } else {
              this.showMaxLimitMessage = false;
            }
          },
          calculateBoxSize() {
            return (this.selectedVariant.quantity * this.selectedVariant.sqmBox).toFixed(2);
          },
          getProductDeliveryDate() {
            const payload = {
                    quantity: this.selectedVariant.quantity,
                    variantId: this.selectedVariant.variantArticleNumber,
                    checkLitiumInventory: true,
                };
            ApiService.getProductDeliveryDate(payload).then((response) => {
                if (response && response.data) {
                    this.selectedVariant.stockStatus = response.data.formattedDateAvailable;
                    this.selectedVariant.inStock = response.data.inStock;
                }
            });
        },
        getProductPriceAndStock(variant: Variant, requestdelay: number) {
            if (!this.quantityChangedTriggered) {            
                this.spinner.spin(this.$el as HTMLElement);
                this.quantityChangedTriggered = true;
                var self = this;
                this.selectedVariant.newQuantity = variant.quantity.toString();
                setTimeout(function () {                
                    const payload: ProductPriceStockRequest = {
                        quantity: variant.quantity,
                        variantId: variant.variantArticleNumber,
                        checkLitiumInventory: true,
                    };
                    
                    ApiService.getProductPriceStock(payload).then((response) => {
                        if (response && response.data ) {
                            let data = response.data;
                            variant.inStock = data.inStock;
                            variant.discountPercent = data.discountPercent;
                            variant.displayDiscountPrice = data.displayDiscountPrice;
                            variant.displayPrice = data.displayPrice;
                            variant.stockStatus = data.dateAvailable;    
                            self.selectedVariant.inStock = data.inStock;       
                            self.$forceUpdate();        
                        }
                    })
                    self.quantityChangedTriggered = false;
                    self.spinner.stop();
                }, requestdelay);
            }
        },
        addToCart(){
            this.spinner.spin(this.$el as HTMLElement);
            const payload: AddOrUpdateCartItem = {
                articleNumber: this.selectedVariant.variantArticleNumber,
                quantity: this.selectedVariant.quantity,
                rowSystemId: this.selectedVariant.rowSystemId,
                comment: ''
            };
            var header = <HTMLElement>document.querySelector(".header");
            const scrollUp = "scroll-up";
            const scrollDown = "scroll-down";
            const currentScroll = window.pageYOffset;
        
            if (currentScroll == 0) {
                header.classList.remove(scrollUp);
                header.classList.remove(scrollDown);
            
            } else {
                header.classList.add(scrollUp);
                header.classList.remove(scrollDown);
    
            }
            
            CheckoutStore.updateState(payload).then(() => {
                this.spinner.stop();
            });
        },
        select(variant: Variant) {
            this.selectedVariant = variant;
            this.getProductPriceAndStock(this.selectedVariant, 0);
        },
        hideAddToCart(){
          return !(this.selectedVariant.outgoingProduct && !(this.selectedVariant.quantity<=this.selectedVariant.outgoingProductInStockQuantity));
        }  
    },
    watch: {
      orderRow(newOrderRow: OrderRow) {
        if (newOrderRow) {
          this.$set(this.selectedVariant, 'rowSystemId', newOrderRow.rowSystemId);
          this.$set(this.selectedVariant, 'quantity', newOrderRow.quantity);
          this.$set(this.selectedVariant, 'newQuantity', newOrderRow.quantity.toString());
          this.getProductPriceAndStock(this.selectedVariant, 0);
          this.checkMaximumQuantitySize();
        }
      }
    }
};


