import {FavouriteStore} from '@/store/bricmate-b2c/favouriteState'
import {ApiService} from '@/services/bricmate/ApiService';

export default {
    name: "FavouriteListing",
    props: ['generatePdfUrl'],
    data() {
        return {
            favourites: [],
        };
    },
    computed: {
        translations: function(){
            return window.__litium.translation;
        }
    },
    methods: {    
        openPdf(){
            var token = window.localStorage.getItem("requestVerificationToken");
            var verificationToken='';
            const favIds = FavouriteStore.favouritesIds.join('|');
            if(token!=null){
                verificationToken = token.split(':')[0];
            }
    
            if(verificationToken!=''){
                var pdfUrl = this.generatePdfUrl+"&productIds="+favIds +"&token="+verificationToken;
                window.open(pdfUrl,"_blank");
            }
        },
        removeFromFavouritesClick(id) {           
            FavouriteStore.removeFromFavouriteState(id);

            const favIds = FavouriteStore.favouritesIds;
            const favIdsArray: string[] = [];
            //Fetch favourites from the store
            //APi call and fetch data from the backend
            if(favIds.length > 0){
                favIds.forEach(ids => {
                    favIdsArray.push(ids.toString());
                });
            }       
            
            ApiService.fetchFavourites(favIdsArray).then((response => {
                this.favourites = response.data;
            })); 
        },  
    },
    mounted: function(){
        const favIds = FavouriteStore.favouritesIds;
        const favIdsArray: string[] = [];
        //Fetch favourites from the store
        //APi call and fetch data from the backend
        if(favIds.length > 0){
            favIds.forEach(ids => {
                favIdsArray.push(ids.toString());
            });
            ApiService.fetchFavourites(favIdsArray).then((response => {
                this.favourites = response.data;
            }));        
        }
    }
};