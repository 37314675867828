import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "InteractiveVideo",
  props: ["videourl", "modalModeProp"],

  mounted() {
    const self = this;
    this.$refs.video.addEventListener("timeupdate", (event) => {
      if (!self.hideIntro && self.currentTime !== self.initCurrentTimeSlider) {
        this.$refs.video.load();
        self.hideIntro = true;
      }
    });
    this.$refs.video.onloadedmetadata = (event) => {
      self.initiated = true;
      self.maxTime = Math.floor(event.target.duration * 10) / 10;
    };
  },

  data() {
    return {
      currentTime: 1.54,
      maxTime: 10,
      initiated: false,
      hideIntro: false,
      initCurrentTime: 0.001, // Hack for iOS to get video thumbnail
      initCurrentTimeSlider: 1.54,
      modalMode: this.modalModeProp ? this.modalModeProp : null,
      openModal: false,
    };
  },

  components: {
    VueSlider,
  },

  computed: {
    translations() {
      return window.__litium.translation;
    },
    getDotSize() {
      return window.innerWidth < 1024 ? [70, 48] : [38, 22];
    },
  },
  methods: {
    onChange(value) {
      const myVideo = this.$refs.video;
      this.currentTime = value;
      if (myVideo) {
        myVideo.currentTime = value;
      }
    },
    handleModal() {
      this.openModal = !this.openModal;
      // this.modalMode = true;
    }
  },
};
