import { architectCatalog } from '@/models/bricmate/architectOrder';
import { ApiService } from '@/services/bricmate/ApiService';
import ThankYouModal from './ThankYouModal.vue';

export default {
    name: "ArchitectsOrderCatalog",
    props: ["thankYouMsg", "thankYouHeader"],
    components: {ThankYouModal},
    data() {
        return {
            userForm: {
                companyName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',                            
            } as architectCatalog,
            showModal: false,
        };
    },
    computed: {
        translations(){
            return window.__litium.translation;
        }
    },
    methods: {
        onSubmit(){
            ApiService.orderArchitectsCatalog(this.userForm);
            this.showModal = true;
            
        },
        resetForm() {
            this.userForm = {
                companyName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',            
            };
            this.$nextTick(() => this.$refs.observer.reset());
        },
        handleCloseModal(){
            this.showModal = false;
            this.resetForm();
        }
    },

};