import { Component, Prop, Vue } from "vue-property-decorator";
import { CountriesArray, Country } from "@/models/bricmate/countries"


@Component
export default class BricmateCountryPicker extends Vue {
    @Prop()
    protected model!: CountriesArray;
    protected open: boolean = false;

    private selectedCountry: Country;

    constructor() {
        super();
        this.selectedCountry = getPreSelectedCountry(this.model);
    }

    selectLangIdAndCountry(country: Country) {
        this.selectedCountry = country;
    }

    isSelected(country: Country): boolean {
        return country.selected;
    }
}

function getPreSelectedCountry(model: CountriesArray): Country {
    var selectedCountry: Country = { countryName: "", countryId: "", languageName: "", websiteUrl: "", selected: false, countryCode: "", languageCode: "" };
    for (let index = 0; index < model.length; index++) {
        const country = model[index];
        for (let index = 0; index < country.length; index++) {
            const element = country[index] as Country;
            if (element.selected) {
                selectedCountry = country[index];
                break;
            }
        }
    }

    return selectedCountry

}

