
export default {
    name: "ProductPrint",
        data() {
        return {
        };
    },
    computed: {
        translations: function(){
            return window.__litium.translation;
        }
    },
    methods: {
        handleClick() {           
          window.print();
        },        
    },

};