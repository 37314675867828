
export default {
    name: "Indicators",
    props: { elements: Array, currentElementIndex: Number, showElement: Function },
    data() {
        return {
        };
    },
    computed: {
        
    },
    methods: {
            
    },
};