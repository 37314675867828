import Carousel from "@/components/bricmate/carousel/Carousel.vue";


export default {
    name: "ProductPopUpModal",
    props: ['productimgsrc', 'variantname', 'productimgsrcset', "openproductmodal", "standalonemode"],
    components: { Carousel },
    data() {
        return {
            internalProp: false,
        };
    },
    computed: {
        translations() {
            return window.__litium.translation;
        }
    },
    methods: {
        closePopUp(){
            if(this.standalonemode){
                this.internalProp = !this.internalProp; 
            }else{
                this.$emit("handleToggleShowPopUp");
            }
        }
    },

};