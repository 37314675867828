import {FavouriteStore} from '@/store/bricmate-b2c/favouriteState'

export default {
    name: "AddToFavourite",
    props: ['id'],
    data() {
        return {
        };
    },
    computed: {
        checkedBox: function(){
            return FavouriteStore.favouritesIds.includes(this.id);
        },
        translations(){
            return window.__litium.translation;
        }
    },
    methods: {
        handleClick(id) {           
            FavouriteStore.addToFavouriteIdsState(id);
        },       
    },

};