export default {
    name: "MenuLink",
    props: ['link'],
    data() {
      return {
          showSubMenu: false,
      };
    },
    methods: {
        handleshowSubMenu() {
            this.showSubMenu = !this.showSubMenu;
        }
    },
  };