import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import "../../models/Window";
import { orderCatalog } from "@/models/bricmate/orderCatalog";
import { searchQuery } from "@/models/bricmate/searchQuery";
import { IFavourites } from "@/models/bricmate/favourites";
import { orderSamples } from '@/models/bricmate/orderSamples';
import { CheckoutModel, CampaignModel } from "@/models/tapwell/Checkout";
import { Cart, AddOrUpdateCartItem, ReorderCart, ProductPriceStockRequest, ProductPriceStock, ProductAvailableResponse, JeevesPriceRequest, JeevesPriceResponse } from "@/models/tapwell/Cart";
import { architectCatalog, architectCollection } from '@/models/bricmate/architectOrder';

// const GOOGLE_API_KEY = "AIzaSyARjUhQ5qjSEChDQGQi8nsKwgY-2ha_v-U"

function errorResponse(e: any): Promise<AxiosResponse<any>> {
  const response: AxiosResponse<boolean> = {
    data: false,
    status: e.response.status,
    statusText: e.response.data,
    headers: {},
    request: {},
    config: null as unknown as AxiosRequestConfig,
  };
  return Promise.resolve(response);
}

export const ApiService =
{
  async search(model: searchQuery): Promise<AxiosResponse<searchQuery>> {
    try {
      return await axios.post("/api/search", model, {
        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
        },
      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async fetchStores(): Promise<AxiosResponse<searchQuery>> {
    try {
      return await axios.get("/api/bricmateb2cfindmystore/stores", {
        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
        },
      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async fetchFavourites(productIds: string[]): Promise<AxiosResponse<string[]>> {
    try {
      return await axios.post("/api/bricmateb2cproductfavorites/getproductfavorites", productIds, {

        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
          'content-type': 'application/json',
        },

      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async orderSamples(model: orderSamples): Promise<AxiosResponse<string[]>> {
    try {
      return await axios.post("/api/bricmateb2cproductfavorites/sendproductfavoritesmail", model, {

        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
          'content-type': 'application/json',
        },

      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async orderArchitectsCatalog(model: architectCatalog): Promise<AxiosResponse<string[]>> {
    try {
      return await axios.post("api/bricmateb2carchitectsordering/sendcatalogrequest", model, {

        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
          'content-type': 'application/json',
        },

      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async orderArchitectsCollection(model: architectCollection): Promise<AxiosResponse<string[]>> {
    try {
      return await axios.post("api/bricmateb2carchitectsordering/sendcollectionrequest", JSON.stringify(model), {

        headers: {
          "litium-request-context": JSON.stringify(window.__litium.requestContext),
          "RequestVerificationToken": window.localStorage.getItem("requestVerificationToken"),
          'content-type': 'application/json',
        },

      });
    } catch (e) {
      return errorResponse(e);
    }
  },

  async getProductDeliveryDate(request: ProductPriceStockRequest): Promise<AxiosResponse<ProductAvailableResponse>> {
    try {
        return await axios.post("/api/cart/productdeliverydate", request, {
            headers: {
                "litium-request-context": JSON.stringify(window.__litium.requestContext),
            },
        });
    } catch (e) {
        return errorResponse(e);
    }
},
async getProductPriceStock(request: ProductPriceStockRequest): Promise<AxiosResponse<ProductPriceStock>> {
  try {
      return await axios.post("/api/cart/productpricestock", request, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async checkoutOrder(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
  try {
      return await axios.post("/api/checkout/placeorder", model, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async setDelivery(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
  try {
      return await axios.put("/api/checkout/setDeliveryProvider", model, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async setPaymentProvider(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
  try {
      return await axios.put("/api/checkout/setPaymentProvider", model, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async setShippingOption(model: CheckoutModel): Promise<AxiosResponse<CheckoutModel>> {
  try {
      return await axios.put("/api/klarnacheckout/setShippingOption", model, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async setCampaignCode(campaignModel: CampaignModel): Promise<AxiosResponse<CampaignModel>> {
  try {
      return await axios.put("/api/checkout/setCampaignCode", campaignModel, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async getMiniCart(): Promise<AxiosResponse<Cart>> {
  try {
      return await axios.get("/api/cart", {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async addToCart(cartItem: AddOrUpdateCartItem): Promise<AxiosResponse<Cart>> {
  try {
      return await axios.post("/api/cart/add", cartItem, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async updateCart(cartItem: AddOrUpdateCartItem): Promise<AxiosResponse<Cart>> {
  try {
      return await axios.put("/api/cart/update", cartItem, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async reorderCart(cartItem: ReorderCart): Promise<AxiosResponse<Cart>> {
  try {
      return await axios.post("/api/cart/reorder", cartItem, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},
async placeOrder(cartItem: ReorderCart): Promise<AxiosResponse<Cart>> {
  try {
      return await axios.post("/api/checkout/placeorder", cartItem, {
          headers: {
              "litium-request-context": JSON.stringify(window.__litium.requestContext),
              RequestVerificationToken: window.localStorage.getItem("requestVerificationToken"),
          },
      });
  } catch (e) {
      return errorResponse(e);
  }
},

};
