import ProductPopUpModal from "../ProductPopUpModal.vue";

export default {
    name: "BricmateProduct",
    props: ['variantimagesrcset', 'variantimagesrcurl', 'variantsizeimageurl', "variantname", "productmodalimgsrcset","productmodalimgsrcurl"],
    components: { ProductPopUpModal },
    data() {
        return {
            openProductModal: false,
        };
    },
    computed: {
        translations() {
            return window.__litium.translation;
        }
    },
    methods: {
        toggleShowPopUp(){
            const body = document.body;
            if(this.openproductmodal){           
                body.classList.add("modal-open");
            }else{
                body.classList.remove("modal-open");
            } 
            this.openProductModal = !this.openProductModal;
        }        
    },

};