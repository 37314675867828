import { ApiService } from '@/services/bricmate/ApiService';
import { architectCollection } from '@/models/bricmate/architectOrder';
import ThankYouModal from "./ThankYouModal.vue";

export default {
    name: "ArchitectsOrderCollection",
    props: ["productNames", "thankYouMsg", "thankYouHeader"],
    components:{
        ThankYouModal
    },
    data() {
        return {
            userForm: {
                companyName: '',
                projectName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',
                text: '',
                productsSelected: []
            } as architectCollection,
            allProducts: [],
            showModal: false,
        };
    },
    computed: {
        translations() {
            return window.__litium.translation;
        }
    },
    methods: {
        onSubmit() {            
            for (let index = 0; index < this.allProducts.length; index++) {
                const element = this.allProducts[index];
                if (element.checked) {
                    this.userForm.productsSelected.push(element.name);
                }

            }
            ApiService.orderArchitectsCollection(this.userForm);
            this.showModal = true;
            
        },

        resetForm() {
            this.userForm = {
                companyName: '',
                projectName: '',
                name: '',
                address1: '',
                address2: '',
                postNumber: '',
                city: '',
                email: '',
                tel: '',
                text: '',
                productsSelected: []
            };

            for (let index = 0; index < this.allProducts.length; index++) {
                const element = this.allProducts[index];
                element.checked = false;
            }
            this.$nextTick(() => this.$refs.observer.reset());
        },
        handleCloseModal(){
            this.showModal = false;
            this.resetForm();
        }
    },

    mounted() {
        for (let index = 0; index < this.productNames.length; index++) {
            const element = this.productNames[index];
            const obj = { name: element, checked: false };
            this.allProducts.push(obj);
        }
    }

};