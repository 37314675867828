var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"architects-order-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"companyName","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"companyName"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.companyname"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.companyName),expression:"userForm.companyName"}],attrs:{"type":"text","id":"companyName"},domProps:{"value":(_vm.userForm.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "companyName", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"name","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.name"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.name),expression:"userForm.name"}],attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.userForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "name", $event.target.value)}}})]}}],null,true)}),_c('div',[_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"address1","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"address1"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.address1"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.address1),expression:"userForm.address1"}],attrs:{"type":"text","id":"address1"},domProps:{"value":(_vm.userForm.address1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "address1", $event.target.value)}}})]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"input-container",attrs:{"name":"address2","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"address2"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.address2"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.address2),expression:"userForm.address2"}],attrs:{"type":"text","id":"address2"},domProps:{"value":(_vm.userForm.address2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "address2", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"postNumber","mode":"lazy","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"postNumber"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.postnumber"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.postNumber),expression:"userForm.postNumber"}],attrs:{"type":"text","id":"postNumber"},domProps:{"value":(_vm.userForm.postNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "postNumber", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"city","mode":"lazy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.city"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.city),expression:"userForm.city"}],attrs:{"type":"text","id":"city"},domProps:{"value":(_vm.userForm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "city", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"email","mode":"lazy","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.email"])+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.email),expression:"userForm.email"}],attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.userForm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "email", $event.target.value)}}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"input-container required",attrs:{"name":"tel","mode":"lazy","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{class:{ error: errors.length > 0 },attrs:{"for":"tel"}},[_vm._v(_vm._s(_vm.translations["architectsordercatalog.tel"])+":")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userForm.tel),expression:"userForm.tel"}],attrs:{"type":"tel","id":"tel"},domProps:{"value":(_vm.userForm.tel)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userForm, "tel", $event.target.value)}}})]}}],null,true)}),_c('div',{staticClass:"architects-order-form__btn"},[_c('button',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.translations["architectsordercatalog.sendform"])+" ")])])],1)]}}])}),(_vm.showModal)?_c('thank-you-modal',{class:{'thank-you-modal--open' : _vm.showModal},attrs:{"thankYouMsg":_vm.thankYouMsg,"thankYouHeader":_vm.thankYouHeader},on:{"closeModal":_vm.handleCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }